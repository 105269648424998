export function parseData(data: string) {
  if (typeof data !== "string") {
    return {};
  }
  try {
    return JSON.parse(data);
  } catch (error) {
    return {};
  }
}
